import { Col, Modal, Row, Spin } from 'antd'
import useConditionnementHook from '../../hook/useConditionnementHook'
import Identification from '../ConditionnementDetail/Identification'
import InformationGeneral from '../ConditionnementDetail/InformationGeneral'
import Localisation from '../ConditionnementDetail/Localisation'
import Blocage from '../../../components/Blocage'

const ConditionnementPopup = () => {
  const { loading, setLoading, onCancel, listSscc, cdnData, fetchCdnData } =
    useConditionnementHook()

  return (
    <Modal
      prefixCls="modal-new-stock"
      centered
      open={true}
      onCancel={onCancel}
      width={'90%'}
      className="border-solid border-secondary rounded-xl"
      footer={null}
      destroyOnClose={true}
    >
      <Spin spinning={loading} style={{ overflow: 'auto' }}>
        <Row className="bd-bot-page-title pb-3.5" style={{ height: '8%' }}>
          <Col className="flex items-center">
            <div className="flex items-baseline">
              <span className="modal-title-heading-1 mr-2">
                Conditionnement
              </span>
              <span className=" modal-title-heading-2 normal-case">
                (n° {cdnData?.reference_number})
              </span>
            </div>
          </Col>
        </Row>
        <div className="gap-9"></div>
        <div className="overflow-y-auto main-content-mission ">
          <Identification initData={cdnData} />
          <Localisation initData={cdnData} />
          <InformationGeneral initData={cdnData} />
          {cdnData ? (
            <Blocage
              listSscc={listSscc}
              cdn={cdnData}
              refetchData={fetchCdnData}
              setLoading={setLoading}
            />
          ) : null}
        </div>
      </Spin>
    </Modal>
  )
}

export default ConditionnementPopup
