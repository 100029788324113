import { Col, Row } from 'antd'
import SectionLayout from '../SectionLayout'
import { CInput } from '../Wrapper'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ConditionnementDetail } from '../../../../../models'
import { StatusStock } from '../../../../../enum/StatusStock'
import { StorageFunc } from '../../../../../enum/storageFunc'

const Localisation = (props: { initData?: ConditionnementDetail }) => {
  const { initData } = props
  const { t } = useTranslation()

  const renderBlockStatus = () => {
    // if (!initData?.blocage_entree && !initData?.blocage_sortie)
    //   return t('notBlock')
    // else if (initData?.blocage_entree && initData?.blocage_sortie)
    //   return `${t('entry')} & ${t('sortie')}`
    // else if (initData?.blocage_entree) return t('entry')
    // else return t('sortie')
    return t('notBlock')
  }

  const isDisplayNiveauAndLocation = () => {
    return initData?.cur_location_code && initData?.cur_location_code !== '00'
  }

  return (
    <SectionLayout title="Localisation">
      <Row>
        <Col lg={24} xl={{ span: 3 }}>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 7 }} className="form-label">
              Zone
            </Col>
            <Col lg={21} xl={{ span: 10, offset: 1 }}>
              <CInput
                value={initData?.cur_zone_code}
                className="custom-input"
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col lg={24} xl={{ span: 3 }}>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 7 }} className="form-label">
              Allée
            </Col>
            <Col lg={21} xl={{ span: 10, offset: 1 }}>
              <CInput
                value={initData?.cur_location_aisle}
                className="custom-input"
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col lg={24} xl={{ span: 4 }}>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 7 }} className="form-label">
              Travée
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput
                value={initData?.cur_location_bay}
                className="custom-input"
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col lg={24} xl={{ span: 4 }}>
          {isDisplayNiveauAndLocation() && (
            <>
              <Row className="mb-2.5">
                <Col lg={3} xl={{ span: 9 }} className="form-label">
                  Niveau
                </Col>
                <Col lg={21} xl={{ span: 8, offset: 1 }}>
                  <CInput
                    value={initData?.cur_location_code?.[1]}
                    className="custom-input"
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mb-2.5">
                <Col lg={3} xl={{ span: 9 }} className="form-label">
                  Position
                </Col>
                <Col lg={21} xl={{ span: 8, offset: 1 }}>
                  <CInput
                    value={initData?.cur_location_code?.[0]}
                    className="custom-input"
                    disabled
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col lg={24} xl={{ span: 5 }}>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 11 }} className="form-label">
              Entrée (empl.)
            </Col>
            <Col lg={21} xl={{ span: 12 }}>
              <CInput
                value={
                  initData?.updated_entry_time &&
                  initData?.storage_func !== StorageFunc.REC
                    ? moment(
                        Number(initData?.updated_entry_time) * 1000
                      ).format(t('date-format'))
                    : '-'
                }
                className="custom-input"
                disabled
              />
            </Col>
          </Row>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 11 }} className="form-label">
              Blocage (empl.)
            </Col>
            <Col lg={21} xl={{ span: 12 }}>
              <CInput
                value={renderBlockStatus}
                className="custom-input"
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col lg={24} xl={{ span: 5 }}>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 14 }} className="form-label">
              Sortie prévue (empl.)
            </Col>
            <Col lg={21} xl={{ span: 9 }}>
              <CInput
                value={
                  initData?.chargement_at &&
                  initData.status === StatusStock.CORRESPOND
                    ? moment(Number(initData?.chargement_at) * 1000).format(
                        t('date-format')
                      )
                    : '-'
                }
                className="custom-input"
                disabled
              />
            </Col>
          </Row>
          {/* <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 14 }} className="form-label">
              Date de libération
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput className="custom-input" disabled />
            </Col>
          </Row> */}
        </Col>
      </Row>
    </SectionLayout>
  )
}

export default Localisation
