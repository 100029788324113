import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import conditionnementApi from '../../../../http/conditionnementApi'
import { ConditionnementDetail } from '../../../../models'
import { getCurrentFiltersText } from '../../../../utils'

const useConditionnementHook = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const pageIndex = Number(searchParams.get('page-index')) || 1
  const pageSize = Number(searchParams.get('page-size')) || 25
  const [loading, setLoading] = useState<boolean>(false)
  const [cdnData, setCdnData] = useState<ConditionnementDetail>()
  const [listSscc, setListSSCC] = useState<any[]>([])
  const condition = Object.fromEntries(
    new URLSearchParams(window.location.search)
  )

  const fetchCdnData = async () => {
    if (!condition.sscc_stock) return
    setLoading(true)
    try {
      const [response, listSscc] = await Promise.all([
        conditionnementApi.getSsccInfoInStock(condition.sscc_stock),
        conditionnementApi.getListStock(
          condition.ref_id_stock,
          condition.lot_stock
        ),
      ])
      setCdnData(response.data.entry)
      setListSSCC(listSscc.data.entry)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      onCancel()
    }
  }

  useEffect(() => {
    fetchCdnData()
  }, [])

  const onCancel = () => {
    navigate(
      `/gestion-de-stock/stock?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText(
        ['sscc_stock', 'lot_stock', 'ref_id_stock', 'stock_id']
      )}`
    )
  }

  return {
    loading,
    cdnData,
    onCancel,
    listSscc,
    fetchCdnData,
    setLoading,
  }
}

export default useConditionnementHook
