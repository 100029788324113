import { Col, Row } from 'antd'
import SectionLayout from '../SectionLayout'
import { CInput } from '../Wrapper'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ConditionnementDetail } from '../../../../../models'

const InformationGeneral = (props: { initData?: ConditionnementDetail }) => {
  const { initData } = props
  const { t } = useTranslation()

  return (
    <SectionLayout title="Informations générales">
      <Row>
        <Col lg={24} xl={{ span: 8 }}>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 6 }} className="form-label">
              1ère entrée
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput
                value={
                  initData?.created_at
                    ? moment(Number(initData?.created_at) * 1000).format(
                        t('date-format')
                      )
                    : 0
                }
                className="custom-input"
                disabled
              />
            </Col>
          </Row>
          {/* <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 8 }} className="form-label">
              Dernière sortie
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput
                value={
                  initData?.loading_validation_at
                    ? moment(
                        Number(initData?.loading_validation_at) * 1000
                      ).format(t('date-format'))
                    : 0
                }
                className="custom-input"
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xl={{ span: 8 }} className="form-label">
              Rotation
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput className="custom-input" disabled />
            </Col>
          </Row> */}
        </Col>
        {/* <Col lg={24} xl={{ span: 8 }}>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 8 }} className="form-label">
              Stock total actuel
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput className="custom-input" disabled />
            </Col>
          </Row>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 8 }} className="form-label">
              Stock total réservé
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput className="custom-input" disabled />
            </Col>
          </Row>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 8 }} className="form-label">
              Stock total bloqué
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput className="custom-input" disabled />
            </Col>
          </Row>
        </Col>
        <Col lg={24} xl={{ span: 8 }}>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 6 }} className="form-label">
              Nb.C
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput className="custom-input" disabled />
            </Col>
          </Row>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 6 }} className="form-label">
              J.M.S
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput className="custom-input" disabled />
            </Col>
          </Row>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 6 }} className="form-label">
              Coef.
            </Col>
            <Col lg={21} xl={{ span: 8, offset: 1 }}>
              <CInput className="custom-input" disabled />
            </Col>
          </Row>
        </Col> */}
      </Row>
    </SectionLayout>
  )
}

export default InformationGeneral
