import { Col, Row } from 'antd'
import SectionLayout from '../SectionLayout'
import { CInput } from '../Wrapper'
import { StatusStock } from '../../../../../enum/StatusStock'
import { useTranslation } from 'react-i18next'
import { ConditionnementDetail } from '../../../../../models'
import { formatDateTime } from '../../../../../utils'

const Identification = (props: { initData?: ConditionnementDetail }) => {
  const { initData } = props
  const { t } = useTranslation()

  const renderEtat = () => {
    if (
      initData?.blocage_entree ||
      initData?.blocage_sortie ||
      initData?.sscc_block ||
      initData?.entry_block ||
      (initData?.lot_reference?.lot_block && !initData?.is_skip_block_lot)
    ) {
      return t('block')
    } else {
      if (initData?.status === StatusStock.CORRESPOND) {
        return t('stockEtat1')
      } else if (initData?.status === StatusStock.CREATE) {
        return t('stockEtat0')
      }
    }
  }

  return (
    <SectionLayout title="Identification">
      <Row>
        <Col lg={24} xl={{ span: 8 }}>
          <Row className="mb-2.5">
            <Col lg={3} xl={{ span: 6 }} className="form-label">
              N° SSCC
            </Col>
            <Col lg={21} xl={{ span: 12, offset: 1 }}>
              <CInput
                value={initData?.sscc}
                className="custom-input"
                disabled
              />
            </Col>
          </Row>
          {initData?.lot && (
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label">
                N° de lot
              </Col>
              <Col lg={21} xl={{ span: 12, offset: 1 }}>
                <CInput
                  value={initData?.lot}
                  className="custom-input"
                  disabled
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col lg={3} xl={{ span: 6 }} className="form-label">
              Etat
            </Col>
            <Col lg={21} xl={{ span: 12, offset: 1 }}>
              <CInput value={renderEtat} className="custom-input" disabled />
            </Col>
          </Row>
        </Col>
        <Col lg={24} xl={{ span: 8 }}>
          {initData?.dluo && (
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label">
                DLUO
              </Col>
              <Col lg={21} xl={{ span: 8, offset: 1 }}>
                <CInput
                  value={formatDateTime(initData.dluo)}
                  className="custom-input"
                  disabled
                />
              </Col>
            </Row>
          )}
          {initData?.dlv_value && (
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label">
                DLV
              </Col>
              <Col lg={21} xl={{ span: 8, offset: 1 }}>
                <CInput
                  value={initData?.dlv_value}
                  className="custom-input"
                  disabled
                />
              </Col>
            </Row>
          )}
          {initData?.dlc_value && (
            <Row className="mb-2.5">
              <Col lg={3} xl={{ span: 6 }} className="form-label">
                DLC
              </Col>
              <Col lg={21} xl={{ span: 8, offset: 1 }}>
                <CInput
                  value={initData?.dlc_value}
                  className="custom-input"
                  disabled
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </SectionLayout>
  )
}

export default Identification
